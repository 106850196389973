var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.tableHeaders,"items":_vm.dataTable.data,"page":_vm.dataTable.page,"items-per-page":_vm.paginate ? _vm.dataTable.data.length : _vm.dataTable.limit,"loading":_vm.dataTable.loadingTable,"show-expand":"","fixed-header":"","height":"500","disable-pagination":"","hide-default-footer":""},on:{"update:page":function($event){return _vm.$set(_vm.dataTable, "page", $event)}},scopedSlots:_vm._u([{key:"item.detail",fn:function({ item }){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticStyle:{"background":"#3e4295"},attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.$router.push({
                path: 'student-manual',
                query: { id: item.id }
              })}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-square-edit-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("master_data.student.edit_student")))])])]}},{key:"item.action",fn:function({ item }){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(_vm.actionRequest)?_c('v-btn',_vm._g({attrs:{"disabled":_vm.dataTable.loadingCheck,"icon":"","small":""},on:{"click":function($event){return _vm.requestMember(item)}}},on),[_c('v-icon',{attrs:{"color":`${item.is_join === 1 ? 'primary' : 'grey'}`}},[_vm._v(" "+_vm._s(item.is_waiting ? "mdi-timer-sand" : "mdi-checkbox-marked-circle")+" ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getTxtButton(item)))])]),_c('div',[(_vm.actionView)?_c('v-btn',{staticClass:"primary mr-2",attrs:{"icon":"","small":"","dark":""},on:{"click":function($event){return _vm.$router.push({
              name: 'ExtracurricularDetail',
              params: { id: item.id }
            })}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye")])],1):_vm._e(),(_vm.actionView)?_c('v-btn',{staticClass:"primary",attrs:{"icon":"","small":"","dark":""},on:{"click":function($event){return _vm.toggleFormDialog(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil-box-outline")])],1):_vm._e()],1)]}},{key:"expanded-item",fn:function({ headers, item }){return [(item.desc && item.desc.length > 0)?_c('td',{attrs:{"colspan":headers.length}},[_c('v-simple-table',{staticClass:"my-4",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.$t("app.teacher")))]),_c('th',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.$t("extracuricular.day")))]),_c('th',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.$t("extracuricular.hours")))])])]),_c('tbody',_vm._l((item.desc),function(d,idx){return _c('tr',{key:`${idx}-item`},[_c('td',_vm._l((d.coach),function(c,i){return _c('div',{key:`${i}-coach`},[_vm._v(" - "+_vm._s(c.name)+" ")])}),0),_c('td',[_vm._v(_vm._s(d.day))]),_c('td',[_vm._v(_vm._s(d.hours))])])}),0)]},proxy:true}],null,true)})],1):_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"my-4 d-flex justify-center"},[_c('span',[_vm._v(_vm._s(_vm.$t("app.no_data")))])])])]}}])}),(_vm.paginate)?_c('Pagination',{attrs:{"length":_vm.dataTable.length,"total":_vm.dataTable.data.length,"current-page":_vm.dataTable.page,"limit":_vm.dataTable.limit,"handler":_vm.paginateChange}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }