<template>
  <div>
    <v-data-table
      :headers="tableHeaders"
      :items="dataTable.data"
      :page.sync="dataTable.page"
      :items-per-page="paginate ? dataTable.data.length : dataTable.limit"
      :loading="dataTable.loadingTable"
      show-expand
      fixed-header
      height="500"
      disable-pagination
      hide-default-footer
      class="elevation-0"
    >
      <template #item.detail="{ item }">
        <v-tooltip left>
          <template #activator="{ on }">
            <v-btn
              icon
              dark
              v-on="on"
              style="background: #3e4295"
              @click="
                $router.push({
                  path: 'student-manual',
                  query: { id: item.id }
                })
              "
            >
              <v-icon small>mdi-square-edit-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("master_data.student.edit_student") }}</span>
        </v-tooltip>
      </template>
      <template #item.action="{ item }">
        <v-tooltip left>
          <template #activator="{ on }">
            <v-btn
              v-on="on"
              v-if="actionRequest"
              :disabled="dataTable.loadingCheck"
              icon
              small
              @click="requestMember(item)"
            >
              <v-icon :color="`${item.is_join === 1 ? 'primary' : 'grey'}`">
                {{
                  item.is_waiting
                    ? "mdi-timer-sand"
                    : "mdi-checkbox-marked-circle"
                }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ getTxtButton(item) }}</span>
        </v-tooltip>
        <div>
          <v-btn
            v-if="actionView"
            icon
            small
            dark
            class="primary mr-2"
            @click="
              $router.push({
                name: 'ExtracurricularDetail',
                params: { id: item.id }
              })
            "
          >
            <v-icon small>mdi-eye</v-icon>
          </v-btn>
          <v-btn
            v-if="actionView"
            icon
            small
            dark
            class="primary"
            @click="toggleFormDialog(item)"
          >
            <v-icon small>mdi-pencil-box-outline</v-icon>
          </v-btn>
        </div>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td v-if="item.desc && item.desc.length > 0" :colspan="headers.length">
          <v-simple-table class="my-4">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">{{ $t("app.teacher") }}</th>
                  <th class="text-left">{{ $t("extracuricular.day") }}</th>
                  <th class="text-left">{{ $t("extracuricular.hours") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(d, idx) in item.desc" :key="`${idx}-item`">
                  <td>
                    <div v-for="(c, i) in d.coach" :key="`${i}-coach`">
                      - {{ c.name }}
                    </div>
                  </td>
                  <td>{{ d.day }}</td>
                  <td>{{ d.hours }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </td>
        <td v-else :colspan="headers.length">
          <div class="my-4 d-flex justify-center">
            <span>{{ $t("app.no_data") }}</span>
          </div>
        </td>
      </template>
    </v-data-table>

    <Pagination
      v-if="paginate"
      :length="dataTable.length"
      :total="dataTable.data.length"
      :current-page="dataTable.page"
      :limit="dataTable.limit"
      :handler="paginateChange"
    />
  </div>
</template>

<script>
export default {
  props: {
    dataTable: Object,
    actionDelete: Boolean,
    actionView: Boolean,
    actionRequest: Boolean,
    paginate: Boolean
  },
  components: {
    Pagination: () => import("@/components/Pagination")
  },
  computed: {
    tableHeaders() {
      const isAction =
        this.actionDelete || this.actionView || this.actionRequest;
      let header = [
        { text: "", value: "data-table-expand" },
        {
          text: this.$i18n.t("app.name"),
          sortable: false,
          align: "left",
          value: "nama"
        },
        {
          text: this.$i18n.t("extracuricular.total_student"),
          sortable: false,
          value: "anggota"
        },
        {
          text: this.$i18n.t("extracuricular.description"),
          sortable: false,
          value: "description"
        }
      ];
      const refusal = [
        {
          text: this.$i18n.t("extracuricular.reason_refusal"),
          sortable: false,
          value: "reason"
        }
      ];
      const headerAdmin = [
        {
          text: this.$i18n.t("app.action"),
          sortable: false,
          align: "center",
          width: 100,
          value: "action"
        }
      ];
      if (this.actionRequest) {
        return [...header, ...refusal, ...headerAdmin];
      } else {
        return isAction ? [...header, ...headerAdmin] : header;
      }
    }
  },
  methods: {
    getTxtButton(item) {
      let txt = "";
      if (item.is_waiting === 1) {
        txt = this.$i18n.t("extracuricular.waiting_approval");
      } else if (item.is_join === 1) {
        txt = this.$i18n.t("extracuricular.have_joined");
      } else {
        txt = this.$i18n.t("extracuricular.join");
      }
      return txt;
    },
    paginateChange(page, limit) {
      this.$emit("paginateChange", page, limit);
    },
    view(item) {
      this.$emit("viewStudent", item);
    },
    toggleFormDialog(item) {
      this.$emit("toggleFormDialog", item);
    },
    requestMember(item) {
      if (item.is_join === 0 && item.is_waiting === 0) {
        this.$emit("request", item);
      }
    }
  }
};
</script>
